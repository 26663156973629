export const COLLECTION_NFT_S_FETCH_IN_PROGRESS = 'COLLECTION_NFT_S_FETCH_IN_PROGRESS';
export const COLLECTION_NFT_S_FETCH_SUCCESS = 'COLLECTION_NFT_S_FETCH_SUCCESS';
export const COLLECTION_NFT_S_FETCH_ERROR = 'COLLECTION_NFT_S_FETCH_ERROR';

export const COLLECTION_INFO_CLEAR = 'COLLECTION_INFO_CLEAR';

export const CLASS_TRACE_FETCH_IN_PROGRESS = 'CLASS_TRACE_FETCH_IN_PROGRESS';
export const CLASS_TRACE_FETCH_SUCCESS = 'CLASS_TRACE_FETCH_SUCCESS';
export const CLASS_TRACE_FETCH_ERROR = 'CLASS_TRACE_FETCH_ERROR';

export const TRANSFER_DIALOG_SHOW = 'TRANSFER_DIALOG_SHOW';
export const TRANSFER_DIALOG_HIDE = 'TRANSFER_DIALOG_HIDE';
export const TRANSFER_SUCCESS_SET = 'TRANSFER_SUCCESS_SET';
export const TRANSFER_FAIL_SET = 'TRANSFER_FAIL_SET';

export const BURN_DIALOG_SHOW = 'BURN_DIALOG_SHOW';
export const BURN_DIALOG_HIDE = 'BURN_DIALOG_HIDE';
export const BURN_SUCCESS_SET = 'BURN_SUCCESS_SET';
export const BURN_FAIL_SET = 'BURN_FAIL_SET';

export const CHAIN_ID_SET = 'CHAIN_ID_SET';
export const TRANSFER_ADDRESS_SET = 'TRANSFER_ADDRESS_SET';
export const NFT_ID_SET = 'NFT_ID_SET';

export const COLLECTION_TRACE_FETCH_IN_PROGRESS = 'COLLECTION_TRACE_FETCH_IN_PROGRESS';
export const COLLECTION_TRACE_FETCH_SUCCESS = 'COLLECTION_TRACE_FETCH_SUCCESS';
export const COLLECTION_TRACE_FETCH_ERROR = 'COLLECTION_TRACE_FETCH_ERROR';
export const TRACE_COLLECTION_SET = 'TRACE_COLLECTION_SET';

export const COLLECTION_HASH_FETCH_IN_PROGRESS = 'COLLECTION_HASH_FETCH_IN_PROGRESS';
export const COLLECTION_HASH_FETCH_SUCCESS = 'COLLECTION_HASH_FETCH_SUCCESS';
export const COLLECTION_HASH_FETCH_ERROR = 'COLLECTION_HASH_FETCH_ERROR';
export const HASH_COLLECTION_SET = 'HASH_COLLECTION_SET';
