export const ASSET_TITLE_SET = 'ASSET_TITLE_SET';
export const DESCRIPTION_SET = 'DESCRIPTION_SET';
export const COLLECTION_SET = 'COLLECTION_SET';
export const TRANSFER_STATUS_SET = 'TRANSFER_STATUS_SET';
export const EXTENSIBLE_STATUS_SET = 'EXTENSIBLE_STATUS_SET';
export const NSFW_STATUS_SET = 'NSFW_STATUS_SET';
export const ROYALTY_SHARE_STATUS_SET = 'ROYALTY_SHARE_STATUS_SET';
export const ROYALTY_SHARE_SET = 'ROYALTY_SHARE_SET';
export const WHITELIST_VALUE_SET = 'WHITELIST_VALUE_SET';

export const BULK_MINT_SET = 'BULK_MINT_SET';
export const SUFFIX_VALUE_SET = 'SUFFIX_VALUE_SET';
export const SUFFIX_COUNT_SET = 'SUFFIX_COUNT_SET';
export const MEDIA_URL_SET = 'MEDIA_URL_SET';
export const PREVIEW_URL_SET = 'PREVIEW_URL_SET';

export const MINT_NFT_CONFIRM_DIALOG_SHOW = 'MINT_NFT_CONFIRM_DIALOG_SHOW';
export const MINT_NFT_CONFIRM_DIALOG_HIDE = 'MINT_NFT_CONFIRM_DIALOG_HIDE';
