export const CHAIN_VALUE_SET = 'CHAIN_VALUE_SET';
export const TAB_VALUE_SET = 'TAB_VALUE_SET';

export const LIST_NFT_DIALOG_SHOW = 'LIST_NFT_DIALOG_SHOW';
export const LIST_NFT_DIALOG_HIDE = 'LIST_NFT_DIALOG_HIDE';
export const LIST_NFT_SUCCESS_SET = 'LIST_NFT_SUCCESS_SET';
export const LIST_NFT_FAIL_SET = 'LIST_NFT_FAIL_SET';
export const PRICE_VALUE_SET = 'PRICE_VALUE_SET';
export const TOKEN_VALUE_SET = 'TOKEN_VALUE_SET';

export const DE_LIST_DIALOG_SHOW = 'DE_LIST_DIALOG_SHOW';
export const DE_LIST_DIALOG_HIDE = 'DE_LIST_DIALOG_HIDE';
export const DE_LIST_NFT_SUCCESS_SET = 'DE_LIST_NFT_SUCCESS_SET';
export const DE_LIST_NFT_FAIL_SET = 'DE_LIST_NFT_FAIL_SET';

export const MARKETPLACE_NFT_S_FETCH_SUCCESS = 'MARKETPLACE_NFT_S_FETCH_SUCCESS';
export const MARKETPLACE_NFT_S_FETCH_IN_PROGRESS = 'MARKETPLACE_NFT_S_FETCH_IN_PROGRESS';
export const MARKETPLACE_NFT_S_FETCH_ERROR = 'MARKETPLACE_NFT_S_FETCH_ERROR';

export const MARKETPLACE_NFT_S_INFO_FETCH_SUCCESS = 'MARKETPLACE_NFT_S_INFO_FETCH_SUCCESS';
export const MARKETPLACE_NFT_S_INFO_FETCH_IN_PROGRESS = 'MARKETPLACE_NFT_S_INFO_FETCH_IN_PROGRESS';
export const MARKETPLACE_NFT_S_INFO_FETCH_ERROR = 'MARKETPLACE_NFT_S_INFO_FETCH_ERROR';

export const LIST_NFT_SUCCESS = 'LIST_NFT_SUCCESS';
export const LIST_NFT_IN_PROGRESS = 'LIST_NFT_IN_PROGRESS';
export const LIST_NFT_ERROR = 'LIST_NFT_ERROR';

export const DE_LISTED_SUCCESS = 'DE_LISTED_SUCCESS';
export const DE_LISTED_IN_PROGRESS = 'DE_LISTED_IN_PROGRESS';
export const DE_LISTED_ERROR = 'DE_LISTED_ERROR';
