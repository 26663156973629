export const COLLECTIONS_FETCH_IN_PROGRESS = 'COLLECTIONS_FETCH_IN_PROGRESS';
export const COLLECTIONS_FETCH_SUCCESS = 'COLLECTIONS_FETCH_SUCCESS';
export const COLLECTIONS_FETCH_ERROR = 'COLLECTIONS_FETCH_ERROR';

export const COLLECTION_FETCH_IN_PROGRESS = 'COLLECTION_FETCH_IN_PROGRESS';
export const COLLECTION_FETCH_SUCCESS = 'COLLECTION_FETCH_SUCCESS';
export const COLLECTION_FETCH_ERROR = 'COLLECTION_FETCH_ERROR';

export const ALL_COLLECTIONS_FETCH_IN_PROGRESS = 'ALL_COLLECTIONS_FETCH_IN_PROGRESS';
export const ALL_COLLECTIONS_FETCH_SUCCESS = 'ALL_COLLECTIONS_FETCH_SUCCESS';
export const ALL_COLLECTIONS_FETCH_ERROR = 'ALL_COLLECTIONS_FETCH_ERROR';

export const COLLECTION_CONFIRM_DIALOG_SHOW = 'COLLECTION_CONFIRM_DIALOG_SHOW';
export const COLLECTION_CONFIRM_DIALOG_HIDE = 'COLLECTION_CONFIRM_DIALOG_HIDE';
export const CREATE_COLLECTION_NAME_SET = 'CREATE_COLLECTION_NAME_SET';
export const CREATE_COLLECTION_SYMBOL_SET = 'CREATE_COLLECTION_SYMBOL_SET';
export const CREATE_COLLECTION_DESCRIPTION_SET = 'CREATE_COLLECTION_DESCRIPTION_SET';
export const CREATE_COLLECTION_JSON_SCHEMA_SET = 'CREATE_COLLECTION_JSON_SCHEMA_SET';
export const CREATE_COLLECTION_IMAGE_URL_SET = 'CREATE_COLLECTION_IMAGE_URL_SET';
export const SCHEMA_VALUES_SET = 'SCHEMA_VALUES_SET';
export const JSON_TAB_SWITCH_SET = 'JSON_TAB_SWITCH_SET';

export const SCHEMA_SET = 'SCHEMA_SET';
export const AVATAR_UPLOAD_IN_PROGRESS = 'AVATAR_UPLOAD_IN_PROGRESS';
export const AVATAR_UPLOAD_SUCCESS = 'AVATAR_UPLOAD_SUCCESS';
export const AVATAR_UPLOAD_ERROR = 'AVATAR_UPLOAD_ERROR';

export const UPDATE_COLLECTION_SET = 'UPDATE_COLLECTION_SET';
export const CLEAR_COLLECTION_SET = 'CLEAR_COLLECTION_SET';
