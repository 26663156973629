export const NAV_TABS_SET = 'NAV_TABS_SET';

export const CLAIM_FAUCET_DIALOG_SHOW = 'CLAIM_FAUCET_DIALOG_SHOW';
export const CLAIM_FAUCET_DIALOG_HIDE = 'CLAIM_FAUCET_DIALOG_HIDE';

export const FAUCET_SUCCESS_SET = 'FAUCET_SUCCESS_SET';

export const FAUCET_CLAIM_FETCH_IN_PROGRESS = 'FAUCET_CLAIM_FETCH_IN_PROGRESS';
export const FAUCET_CLAIM_FETCH_SUCCESS = 'FAUCET_CLAIM_FETCH_SUCCESS';
export const FAUCET_CLAIM_FETCH_ERROR = 'FAUCET_CLAIM_FETCH_ERROR';

export const FAUCET_BALANCE_ADD_IN_PROGRESS = 'FAUCET_BALANCE_ADD_IN_PROGRESS';
export const FAUCET_BALANCE_ADD_SUCCESS = 'FAUCET_BALANCE_ADD_SUCCESS';
export const FAUCET_BALANCE_ADD_ERROR = 'FAUCET_BALANCE_ADD_ERROR';

export const SHOW_SIDE_BAR_SET = 'SHOW_SIDE_BAR_SET';
export const HIDE_SIDE_BAR_SET = 'HIDE_SIDE_BAR_SET';

export const FAUCET_TOKENS_FETCH_IN_PROGRESS = 'FAUCET_TOKENS_FETCH_IN_PROGRESS';
export const FAUCET_TOKENS_FETCH_SUCCESS = 'FAUCET_TOKENS_FETCH_SUCCESS';
export const FAUCET_TOKENS_FETCH_ERROR = 'FAUCET_TOKENS_FETCH_ERROR';
